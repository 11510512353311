<template>
  <div class="container">
    <div class="img"><img src="../assets/img/img-bindphone.png" alt="" /></div>
    <div class="title">会员绑定<img src="../assets/img/icon-phonemember.png" alt="" /></div>
    <div class="item">
      <label for="phone"><span>手机号</span><input id="phone" type="tel" v-model="phoneNumber" maxlength="11" /></label>
    </div>
    <div class="item">
      <label for="code"><span>验证码</span><input id="code" class="code" type="text" v-model="verifyCode" maxlength="8" /></label>
      <a href="javascript:;" @click="getCode" :class="geting ? 'disable' : ''">{{getCodeText}}</a>
    </div>
    <div class="tips"><span>绑定手机号立享会员权益</span></div>
    <div class="btn">
      <a href="javascript:;" @click="submit">绑定</a>
    </div>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  var phoneNumberVerification = /^1[3456789]\d{9}$/;
  var waiting = localStorage.getItem('waiting')
  export default {
    name: 'Phone',
    data () {
      return{
        urls: {
          getLoginCode: 'getLoginCode', // 获取短信
          updateUserPhone: 'userInfo/updateUserPhone' // 绑定手机号
        },
        phoneNumber: '', // 手机号码
        verifyCode: '', // 验证码
        geting: false, // 验证码获取中
        getCodeText: '获取验证码' // 按钮文本
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      console.log('绑定手机')
      if(waiting){
        var myDate = new Date()
        if(myDate.getTime() - waiting < 60000){
          this.geting = true
          this.leftTime(myDate.getTime())
        }else{
          localStorage.removeItem('waiting')
        }
      }
    },
    methods: {
      // 保存
      getCode() {
        let me = this
        if (this.geting) return;
        if(this.phoneNumber.replace(/\s/g, '') == '') {
          Toast('请输入手机号码')
          return
        }
        if (!phoneNumberVerification.test(this.phoneNumber)) {
          Toast('手机号码输入有误')
          return
        }
        this.geting = true
        var myDate = new Date()
        this.leftTime(myDate.getTime())
        this.$post({
          url: this.urls.getLoginCode,
          params: {
            phoneNum: this.phoneNumber
          }
        }).then(rsp => {
          if(rsp.status != 1) {
            Toast('获取验证码失败！')
          }
        }).catch( error => {
            Toast(error.message)
        })
      },
      // 提交数据
      submit() {
        console.log('提交数据')
        var me = this
        if(this.phoneNumber.replace(/\s/g, '') == '') {
          Toast('请输入手机号码')
          return
        }
        if (!phoneNumberVerification.test(this.phoneNumber)) {
          Toast('手机号码输入有误')
          return
        }
        if(this.verifyCode.replace(/\s/g, '') == '') {
          Toast('请输入验证码')
          return
        }
        this.$post({
          url: this.urls.updateUserPhone,
          params: {
            token: this.$global.token,
            terminalCode: 'H5',
            phoneNumber: this.phoneNumber,
            verifyCode: this.verifyCode
          }
        }).then( rsp => {
          if(rsp.status == 1) {
            Toast('绑定成功！')
            setTimeout(() => {
              this.$router.push({
                path:'/my'
              })
            },2000)
          }
        }).catch( error => {
            Toast(error.message)
        })
      },
      // 获取验证码等待倒计时
      leftTime(nowTime) {
        this.geting = true
        var me = this
        var count = 60;
        if(waiting && nowTime - waiting < 60000){
          count = parseInt((60000 - (nowTime - waiting)) / 1000)
        }else{
          localStorage.setItem('waiting', nowTime)
        }
        getSecurityCode()
        var countdown = setInterval(getSecurityCode, 1000);
        function getSecurityCode() {
            me.getCodeText = '重新获取（' + count + '）'
            count--;
            if (count == 0) {
              clearInterval(countdown);
              me.geting = false
              me.getCodeText = '获取验证码'
              localStorage.removeItem('waiting')
            }
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
    .img img{
      width: 100%;
    }
    .title{
      font-weight: bold;
      font-size: 18px;
      margin-top: 10px;
      img{
        vertical-align: text-top;
        margin-left: 5px;
        height: 18px;
      }
    }
    .item{
      width: 100%;
      height: 45px;
      padding: 5px 0;
      box-sizing: border-box;
      position: relative;
      border-bottom: 1px solid #c7c7c7;
      margin-top: 15px;
      label{
        display: block;
        span{
          width: 50px;
          font-size: 16px;
          display: inline-block;
        }
        input{
          width: calc(100% - 60px);
          margin-left: 10px;
          box-sizing: border-box;
          height: 40px;
          padding: 0 10px;
          font-size: 16px;
          line-height: 40px;
          -webkit-appearance: none;
          -webkit-tap-highlight-color:transparent;
          border: none;
          background: none;
        }
        input:focus{
          background: none;
        }
        input.code{
          width: calc(60% - 60px);
        }
      }
      a{
        position: absolute;
        right: 0px;
        top: 5px;
        font-size: 14px;
        color: #74CFB4;
        height: 30px;
        line-height: 34px;
        border-radius: 15px;
        border: 1px solid #74CFB4;
        padding: 0 15px;
      }
      a.disable{
        color: #999;
        border-color: #999;
      }
    }
    .tips{
      height: 1px;
      text-align: center;
      line-height: 0px;
      font-size: 14px;
      margin: 50px 0;
      border-bottom: 1px solid #3f3f3f;
      span{
        color: #3f3f3f;
        background: #fff;
        padding: 0 30px;
      }
    }
    .btn{
      margin-top: 10;
      a{
        display: block;
        text-align: center;
        color: #fff;
        font-size: 18px;
        line-height: 50px;
        // background: linear-gradient(to right, #7ddb4f, #21dd53);
        background: #07B07E;
        border-radius: 25px;
      }
    }
  }
</style>
